.header {
    text-align: center;
    border-bottom: 1px solid #0f0f0f;
}

.header__title {
    display: inline-block;
    font-weight: 700;
    font-size: 16px;
    padding: 16px 0;
}