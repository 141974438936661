

.post-navigation {
    padding: 15px 0;
}

.post-navigation:before,
.post-navigation:after {
    content: " ";
    display: table
}

.post-navigation:after {
    clear: both
}

.post-navigation:before,
.post-navigation:after {
    content: " ";
    display: table
}

.post-navigation:after {
    clear: both
}

.post-navigation .screen-reader-text {
    display: none
}

.post-navigation .nav-links a[rel=next] {
    float: right
}

.post-navigation a {
    font-weight: 600;
    color: #fff;
    text-decoration: none;
}
