.paging-navigation {
    margin: 10px auto;
    text-align: center
}

.paging-navigation:before,
.paging-navigation:after {
    content: " ";
    display: table
}

.paging-navigation:after {
    clear: both
}

.paging-navigation:before,
.paging-navigation:after {
    content: " ";
    display: table
}

.paging-navigation:after {
    clear: both
}


.paging-navigation .page-numbers {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid #fff;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 16px;
    border-radius: 4px;
    user-select: none;
    outline: none !important;
}

.paging-navigation a.page-numbers {
    text-decoration: none;
    color: #fff;
}

.paging-navigation .page-numbers:focus,
.paging-navigation .page-numbers:active:focus,
.paging-navigation .page-numbers.active:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

.paging-navigation .page-numbers:hover,
.paging-navigation .page-numbers:focus {
    color: #fff;
    text-decoration: none
}

.paging-navigation .page-numbers:active,
.paging-navigation .page-numbers.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125)
}

.paging-navigation .page-numbers.disabled,
.paging-navigation .page-numbers[disabled] {
    cursor: not-allowed;
    pointer-events: none;
}

.paging-navigation .page-numbers:hover,
.paging-navigation .page-numbers:focus,
.paging-navigation .page-numbers:active,
.paging-navigation .page-numbers.active  {
    color: #fff;
}



.paging-navigation .page-numbers:hover,
.paging-navigation .page-numbers:focus,
.paging-navigation .page-numbers:active,
.paging-navigation .page-numbers.active {
    border-color: #7f8c8d;
}

.paging-navigation .current,
.paging-navigation .dots {
    color: #fff;
    background-color: #95a5a6;
    border-color: #7f8c8d;
    border-color: transparent !important
}

.paging-navigation .current:hover,
.paging-navigation .dots:hover,
.paging-navigation .current:focus,
.paging-navigation .dots:focus,
.paging-navigation .current:active,
.paging-navigation .dots:active,
.paging-navigation .current.active,
.paging-navigation .dots.active {
    color: #fff;
    background-color: #798d8f;
    border-color: #616d6d
}

.paging-navigation .current:active,
.paging-navigation .dots:active,
.paging-navigation .current.active,
.paging-navigation .dots.active {
    background-image: none
}


.paging-navigation .current:hover,
.paging-navigation .dots:hover,
.paging-navigation .current:focus,
.paging-navigation .dots:focus,
.paging-navigation .current:active,
.paging-navigation .dots:active,
.paging-navigation .current.active,
.paging-navigation .dots.active {
    color: #fff;
    background-color: #a3b1b2;
    border-color: #8c9899
}

.paging-navigation .current:active,
.paging-navigation .dots:active,
.paging-navigation .current.active,
.paging-navigation .dots.active {
    background-color: #8c9899
}
