body {
    background-color: #1d1d1d;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 12px;
    color: #fff;
}

.header .home {
    float: left;
    margin: 10px;
}

a.thumbnail {
    color: #fff;
}

a.thumbnail:visited {
    color: #fff;
}

.thumbnail {
    position: relative;
    margin: 30px 0 0 30px;
    border: 1px solid #fff;
    height: 200px;
}

.thumbnail .overlay {
    position: absolute;
    width: 100%;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, .6));
    bottom: 1px;
    opacity: 0;
    text-shadow: 0 1px 3px rgba(0, 0, 0, .4);
}

.thumbnail:hover .overlay {
    opacity: 1;
}

.overlay h2 {
    min-height: 19px;
    width: 180px;
    margin: 12px 0 5px 15px;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 700;
    white-space: nowrap;
}

.overlay .date {
    display: block;
    margin: 0 0 12px 15px;
    font-size: 11px;
    color: #ccc;
}

.images {
    display: flex;
    flex-wrap: wrap;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.container .full {
    width: 100%;
}

.container .full .image {
    max-height: 1200px;
    max-width: 1200px;
    margin: 20px auto;
    display: block;
}

.tags-links {
    float: right;
}

.tags-links a {
    border: 1px solid #fff;
    padding: 5px;
    border-radius: 3px;
    color: #fff;
    text-decoration: none;
    display: inline-block;
    margin-left: 5px;
}

.album-tags {
    padding-bottom: 3px;
    font-weight: 600;
    color: #fff;
}

.album {
    color: #fff;
    text-decoration: none;
    padding: 6px;
    display: inline-block;
}